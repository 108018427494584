<template>
    <div class="home">
        
        <div class="title">{{ list.title }}</div>
        <div class="content" v-html="list.content"></div>
        

    </div>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            id: '',
            list: ''
        }
    },
    mounted() {
        this.id = this.$route.query.id; // 获取传递的 id
        this.getList()
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1);
        },
        getList() {
            axios
                .get(
                    `https://jdwmapi.huilongjieda.cn/jkshopcloud-platform/ua/user_article/${this.id}`

                )
                .then((res) => {
                    console.log(res);
                    this.list = res.data.data
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }
}
</script>
<style scoped>
page {
    height: 100vh;
    width: 100%;
    background: #f5f5f5;
}
</style>
<style scoped>
.home {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* background: #f5f5f5; */
}

/* 你的样式 */
.title {
    margin-top: 10px;
    font-weight: bold;
}

.content {
    box-sizing: border-box;
    text-align: left;
    width: 100%;
    padding: 14px 14px 44px 14px;
}
</style>