<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script type='text/javascript'>
(function(a, b, c, d, e, j, s) {
    a[d] = a[d] || function() {
        (a[d].a = a[d].a || []).push(arguments)
    };
    j = b.createElement(c),
        s = b.getElementsByTagName(c)[0];
    j.async = true;
    j.charset = 'UTF-8';
    j.src = 'https://static.meiqia.com/widget/loader.js';
    s.parentNode.insertBefore(j, s);
})(window, document, 'script', '_MEIQIA');
_MEIQIA('entId', '4843b41aecf4535d8f00510df3fae798');
</script>


<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
