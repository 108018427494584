<template>
  <div class="home">
    <!-- <van-nav-bar title="热门问题" left-text="" :fixed="true" :placeholder ="true" left-arrow @click-left="onClickLeft" /> -->
    <div class="tabs-boxs">
      <van-tabs v-model="active" color="#02a7ff">
        <van-tab title="热门问题"></van-tab>
      </van-tabs>
    </div>
    <div v-for="(item,index) in list" :key="index" class="tabs-item-box">
      <div @click="onpath(item.id)"  class="tabs-item">
        <div class="title">{{ item.title }}</div>
        <van-icon name="arrow" />
      </div>
    </div>
    
    


  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      active: 1,
      list:[],
      id:'20'
    };
  },
  mounted(){
    this.id = this.$route.query.id||20;
    this.getList()
  },
  methods: {
    onpath(id) {
      this.$router.push({ path: "/index",query: { id } });
    },
    getList() {
      axios
        .get(
          `https://jdwmapi.huilongjieda.cn/jkshopcloud-platform/ua/user_article/list/${this.id}`
          
        )
        .then((res) => {
         this.list=res.data.data
         
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
};

</script>
<style>
page {
  height: 100vh;
  width: 100%;
  background: #FFFFFF;
}
</style>
<style scoped>
.home {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  padding-bottom: 44px;
  box-sizing: border-box;
}

.tabs-boxs {
  width: 100%;
  background: #FFFFFF;
  position: sticky;
  top: 0;
}

.van-tabs {
  width: 100px;
}

.tabs-item-box {
  padding: 0px 12px 0 12px;
  height: 44px;
  width: 100%;
  background: #FFFFFF;
  box-sizing: border-box;
  flex-shrink: 0;

}

.tabs-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  height: 100%;
  border-bottom: 1px solid #EEEEEE;
  box-sizing: border-box;
}

.title {
  width: 320px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
