import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/index',
    name: 'Index',
    component: Index
  },
  
]
  // base: process.env.BASE_URL,
  // base: '/h5',

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  

  routes
})

export default router
